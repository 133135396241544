<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('toggleAddonModal')">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-universeBlue bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-8 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="md:-translate-y-1/4 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
            <div>
              <div>
                <div class="bg-gray-50 border-b flex px-4 py-2 justify-between items-center gap-4">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-starryNight">
                    {{ isCreate ? 'Add' : 'Edit'}} addon
                  </DialogTitle>
                  <div class="flex gap-4">
                    <div v-if="!isCreate">
                      <button
                        class="p-1.5 rounded-md text-red-500 btn-xs btn-transparent flex gap-1"
                        @click="deleteAddon(addon.id)">
                        <TrashIcon class="h-4 w-4" />
                        <span>Delete</span>
                      </button>
                    </div>
                    <button class="px-1 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="$emit('toggleAddonModal')">
                      <span class="sr-only">Close</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div class="mt-2 px-4 sm:px-8 py-4">
                  <div class="grid grid-cols-6 gap-4">
                    <div class="col-span-6">
                      <label for="title" class="block text-sm font-medium text-gray-700">
                        Title
                      </label>
                      <input
                        id="title"
                        v-model="title"
                        type="text"
                        name="title"
                        class="mt-1 block w-full py-2 px-3 sm:text-sm rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-highlight-500 focus:border-highlight-500" />
                      <span class="input-error">{{errorsList.title}}</span>
                    </div>
                    <div class="col-span-2">
                      <label for="price" class="block text-sm font-medium text-gray-700">
                        {{ `Price (in ${productCurrencyCode})` }}
                      </label>
                      <input
                        id="price"
                        v-model="price"
                        type="number"
                        min="0"
                        name="price"
                        class="mt-1 block w-full py-2 px-3 sm:text-sm rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-highlight-500 focus:border-highlight-500" />
                      <span class="input-error">{{errorsList.price}}</span>
                    </div>
                    <div class="col-span-6">
                      <label for="description" class="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <textarea
                        id="description"
                        v-model="description"
                        type="text"
                        name="description"
                        rows="3"
                        maxlength="300"
                        class="mt-1 block w-full py-2 px-3 sm:text-sm rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-highlight-500 focus:border-highlight-500" />
                      <div class="flex justify-between">
                        <span class="input-error">{{errorsList.description}}</span>
                        <div class="text-right mr-1 ml-3">
                          <p class="text-xs text-gray-500 mt-1">{{ description.length }}/300</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-6 border-t">
                      <div class="flex justify-between items-center gap-8 py-3">
                        <div>
                          <p class="block text-sm font-medium text-gray-700">Maximum quantity</p>
                          <p class="text-xs text-gray-500">If the addon has a maximum quantity, please add it here. </p>
                        </div>
                        <div class="flex items-center gap-2">
                          <button class="border border-gray-300 bg-white rounded-full px-1.5 py-1.5
                            text-sm hover:bg-gray-50 focus:outline-none
                            touch-action-manipulation text-starryNight cursor-pointer"
                                  @click="removeQty">
                            <MinusSmIcon class="h-5 w-5" />
                          </button>
                          <span class="mx-1 text-gray-700">{{ qty }}</span>
                          <button class="border border-gray-300 bg-white rounded-full px-1.5 py-1.5
                            text-sm hover:bg-gray-50 focus:outline-none
                            touch-action-manipulation text-starryNight cursor-pointer"
                                  @click="addQty">
                            <PlusSmIcon class="h-5 w-5" />
                          </button>
                        </div>
                        <span class="input-error">{{ errorsList.qty }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense p-4 sm:px-8 bg-gray-100">
              <button type="button" class="w-full inline-flex justify-center rounded-lg border border-transparent shadow-sm px-4 py-2 bg-highlight-600 text-base font-medium text-white hover:bg-highlight-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlight-500 sm:col-start-2 sm:text-sm" @click="submit">
                {{ isCreate ? 'Add Addon' : 'Save' }}
              </button>
              <button ref="cancelButtonRef" type="button" class="mt-3 w-full inline-flex justify-center rounded-lg border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlight-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="$emit('toggleAddonModal')">
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { toRef, computed, watch, ref } from "vue";
import { XIcon, TrashIcon } from '@heroicons/vue/solid';
import * as yup from "yup";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "AddonsSectionAddAddonModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    isCreate: {
      type: Boolean,
      required: true
    },
    currentAddon: {
      type: Object,
      required: true
    }
  },
  emits: ['toggleAddonModal', 'save', 'delete'],
  components: {
    XIcon,
    TrashIcon
  },
  setup(props, { emit }) {
    const addon = toRef(props, 'currentAddon');

    const store = useStore();

    const productCurrencyCode = computed(() => store.getters["product/getCurrentCurrencyCode"]);

    const schema = yup.object({
      title: yup.string().required(),
      description: yup.string().max(300),
      qty: yup.number().required().min(1),
      price: yup.number().required().min(0).transform((v, o) => (o === '' ? 0 : v))
    });


    const { setValues, handleSubmit } = useForm({
      validationSchema: schema,
      validateOnMount: false
    })

    watch(addon, (newVal) => {
      setValues({
        title: newVal.title,
        description: newVal.description,
        qty: newVal.qty,
        price: newVal.price
      })
    })

    const { value: title } = useField('title');
    const { value: description } = useField('description');
    const { value: qty } = useField('qty');
    const { value: price } = useField('price');

    const isGeneralDirty = useIsFormDirty();
    const isGeneralValid = useIsFormValid();

    const isValidSection = computed(() => isGeneralDirty.value && isGeneralValid.value);

    const addQty = () => {
      qty.value += 1;
    }

    const removeQty = () => {
      if(qty.value > 1) {
        qty.value -= 1;
      }
    };

    const errorsList = ref({
      title: null,
      price: null,
      description: null,
      qty: null
    });

    const errorsHandler = ({ errors }) => {
      errorsList.value = errors;
    }

    const submit = handleSubmit(({
      title,
      description,
      qty,
      price
    }, { resetForm }) => {
      if (isValidSection.value) {
        emit('save', {
          id: props.isCreate ? title : addon.value.id,
          title,
          description,
          qty,
          price
        })

        emit('toggleAddonModal');

        resetForm({
          touched: false,
          values: {
            title: "",
            description: "",
            qty: 0,
            price: 0
          }
        })
      }
    }, errorsHandler)

    const deleteAddon = (id) => {
      emit('delete', id);
      emit('toggleAddonModal');
    }

    return {
      addon,
      title,
      errorsList,
      description,
      qty,
      price,
      productCurrencyCode,
      isValidSection,
      addQty,
      removeQty,
      submit,
      deleteAddon
    }

  }
};
</script>

<style scoped>

</style>
