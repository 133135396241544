<template>
  <div>
    <draggable v-model="addonsList" group="addons" class="space-y-2" animation="150" handle=".handle" @move="() => false">
      <template #item="{ element }">
        <div class="bg-white shadow-sm rounded-lg border flex">
          <!--          <div class="w-10 md:w-14 border-r flex items-center justify-center cursor-move handle">-->
          <!--            <DotsVerticalIcon class="w-4 h-4 text-gray-400" />-->
          <!--            <DotsVerticalIcon class="w-4 h-4 text-gray-400 -ml-2.5" />-->
          <!--          </div>-->
          <div class="w-full py-2 px-3 sm:py-3 sm:px-5 flex justify-between items-center gap-6">
            <div class="max-w-md">
              <p class="text-sm sm:text-base leading-4 font-medium text-gray-700">
                {{ element.title }}
              </p>
              <p class="mt-1 line-clamp-2 text-gray-500 text-xs sm:text-sm">
                {{ element.description }}
              </p>
            </div>
            <div class="flex items-center flex-shrink-0 text-right">
              <div class="mr-5 text-xs md:text-sm text-gray-700">
                <span class="block">{{ element.price }} {{currencyCode }}</span>
                <span class="block text-xs">{{ element.qty }} max</span>
              </div>
              <div class="flex items-center gap-2">
                <button class="btn btn-xs btn-white" @click="$emit('editAddon', element)">
                  <PencilIcon class="h-4 w-4 md:mr-1 md:-ml-1" />
                  <span class="hidden md:block">Edit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import { PencilIcon } from '@heroicons/vue/solid';
import draggable from "vuedraggable";

export default {
  name: "AddonsSectionList",
  props: {
    addons: {
      type: Object,
      required: true
    }
  },
  emits: ['editAddon'],
  components: {
    PencilIcon,
    // DotsVerticalIcon,
    draggable
  },
  computed: {
    addonsList() {
      return this.addons
    },
    currencyCode() {
      return this.$store.getters['product/getCurrencyCode'](this.$store.state.product.product.currency)
    }
  }
};
</script>

<style scoped>
.sortable-ghost {
  @apply opacity-30 bg-gray-300 rounded-lg;
}
.sortable-chosen {
  @apply shadow-lg duration-150 ease-in-out
}
</style>
