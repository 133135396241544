<template>
  <AddonsSection
    @checkIsUnsaved="checkForUnsavedSections"
    @checkIsValid="setSectionsValidity" />

  <UnsavedChangesModal
    :isOpen="isModalOpen"
    :isInvalidSectionsPresent="isInvalidSectionsPresent"
    @toggleModal="cancelModal"
    @continue="moveToNextRoute"
    @saveAndContinue="save">
    <template v-slot:title>
      <span class="text-gray-800">
        You have unsaved changes.
      </span>
      <ul class="hidden text-sm text-water font-normal">
        <li v-for="section in unsavedSections" :key="section">
          {{ section.title }}
        </li>
      </ul>
    </template>
    <template v-slot:content>
      <p class="leading-6">You are about to exit the page. <br />All unsaved changes will be lost.</p>
    </template>
    <template v-slot:buttonName>
      Discard
    </template>
  </UnsavedChangesModal>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import AddonsSection from "@/components/views/Listing/Addons/AddonsSection";
import UnsavedChangesModal from "@/components/UI/Popups/Modal/AppUnsavedChangesModal";
import { useUnsavedModal } from "@/use/unsavedModal";

export default {
  name: "Addons",
  components: {
    AddonsSection,
    UnsavedChangesModal
  },
  setup() {
    const store = useStore();

    const route = useRoute();
    const isEditProductMode = !!route.params.id;

    const {
      checkForUnsavedSections,
      setSectionsValidity,
      saveUnsavedSections,
      isInvalidSectionsPresent,
      unsavedSections,
      isModalOpen,
      moveToNextRoute,
      cancelModal
    } = useUnsavedModal(['addons']);

    const addons = computed(() => store.state.product.product.addons);

    const save = () => {
      const preparedData = {
        addons: addons.value.map(addon => ({
          id: typeof addon.id === 'string' ? null : addon.id,
          qty: addon.qty,
          price: addon.price,
          translations: {
            locale: 'en',
            title: addon.title,
            description: addon.description
          }
        }))
      };

      saveUnsavedSections(preparedData, isEditProductMode);
    };

    return {
      checkForUnsavedSections,
      setSectionsValidity,
      isInvalidSectionsPresent,
      unsavedSections,
      isModalOpen,
      moveToNextRoute,
      cancelModal,
      save
    }
  }
}
</script>

<style scoped>

</style>
