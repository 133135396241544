<template>
  <SectionWrapper
    id="addons"
    title="Addons"
    description="Do you have any addons to offer your guests? A bottle of wine, food, snacks, etc.? <a class='relative cursor-pointer rounded-md font-medium text-highlight-600 hover:text-highlight-500' target='_blank' href='https://owayy.slab.com/public/posts/how-to-add-add-ons-to-your-listing-kth8yox2'>Get Inspiration</a>"
    :isValidSection="isAddonsPresent && isSectionUnsaved"
    :show-skip-button="isCreateAddons"
    @saveSection="saveAddons">
    <AddonsSectionList :addons="addons" @editAddon="editAddonModal"></AddonsSectionList>
    <div class="mt-6 sm:mt-10">
      <button
        class="w-full flex justify-center px-6 py-8 border-2 hover:bg-gray-50 border-gray-300 border-dashed rounded-md"
        @click="createAddonModal">
        <div class="flex text-center">
          <span class="block text-gray-600">Create addon</span>
        </div>
      </button>
      <AddonsSectionAddAddonModal
        :isCreate="isCreateAddon"
        :currentAddon="currentAddon"
        :isOpen="isAddonModalOpen"
        @delete="deleteAddon"
        @save="saveAddonModal"
        @toggleAddonModal="toggleAddonModal" />
    </div>
  </SectionWrapper>
</template>

<script>
import { mapGetters } from "vuex";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import AddonsSectionList from "@/components/views/Listing/Addons/AddonsSectionList";
import AddonsSectionAddAddonModal
  from "@/components/views/Listing/Addons/AddonsSectionAddAddonModal";
import { DELETE_ADDON } from "@/graphql/products/mutations";

export default {
  name: "AddonsSection",
  components: { AddonsSectionAddAddonModal, AddonsSectionList, SectionWrapper },
  data() {
    return {
      isAddonModalOpen: false,
      currentAddon: {},
      isCreateAddon: true,
      defaultState: {
        title: "",
        description: "",
        qty: 1,
        price: 1,
        code: "DKK"
      },
      isSectionUnsaved: false
    }
  },
  computed: {
    ...mapGetters('product', ['addons', 'getNextStepCreate']),
    isAddonsPresent() {
      return this.addons.length > 0;
    },
    isCreateAddons() {
      return this.$store.state.product.mode === 'create';
    }
  },
  watch: {
    isSectionUnsaved(value) {
      this.$emit('checkIsUnsaved', {
        title: 'Addons'
      }, value );
    },
    isAddonsPresent(value) {
      if(!value) {
        this.$emit('checkIsUnsaved', {
          title: 'Addons'
        }, value );
      }
    }
  },
  methods: {
    toggleAddonModal() {
      this.isAddonModalOpen = !this.isAddonModalOpen
    },
    createAddonModal() {
      this.currentAddon = this.defaultState;
      this.isAddonModalOpen = true;
      this.isCreateAddon = true;
    },
    editAddonModal(addon) {
      this.isCreateAddon = false;
      this.currentAddon = addon;
      this.isAddonModalOpen = true;
    },
    saveAddonModal(addon) {
      this.isSectionUnsaved = true;
      this.$store.commit('product/setAddons', { addon })
    },
    deleteAddon(id) {
      if(typeof id === "number") {
        this.$apollo.mutate({
          mutation: DELETE_ADDON,
          variables: {
            id
          }
        })
      }
      this.$store.commit('product/removeAddon', { id })
    },
    async saveAddons() {
      if(this.isCreateAddons) {
        this.isSectionUnsaved = false;
        this.$store.commit('product/setData', { step: 'addons' });
        this.$router.push(this.getNextStepCreate);
      } else {
        await this.$store.dispatch('product/updateProduct', { id: +this.$route.params.id , data: {
          addons: this.addons.map(addon => ({
            id: typeof addon.id === 'string' ? null : addon.id,
            qty: addon.qty,
            price: addon.price,
            translations: {
              locale: 'en',
              title: addon.title,
              description: addon.description
            }
          }))
        } }).finally(() => {
          this.isSectionUnsaved = false;
        })
      }
    }
  }
};
</script>

<style scoped>

</style>
